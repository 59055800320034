#app{
  .phone{
    background-image: url("assets/iphone.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height:500px;
    position: relative;
    .screen{
      width: 205px;
      height: 369px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 99;
      img{
        width: 205px;
        height: 369px;
        //border: 1px solid red;
        //width: auto !important;
      }
    }
  }
  .scan div{
    height: auto !important;
    width: 80% !important;
    @media screen and (min-width: 768px){
      width: 100% !important;

    }
  }
}