#countdown{
    .card.countdown-wrap {
        width: 100%;
        padding-top: 30px !important;
        padding-bottom: 30px !important;
        //background: #15171c url('back-coundown.jpg') !important;
        background: linear-gradient(220deg, #4a62ff 0%, #871fff 100%);
        border-radius: 20px !important;

        h3{
            color:#fff;
            font-size: 24px;
        }
        .sub-heading{
            //color:rgba(255,255,255,0.6);
            color:#fff;
            font-weight: bold;
        }
    }
    .card-header,
    .card-footer{
        background: none;
        border: none;
    }
    .card{
        h3.text-navy-blue {
            margin-top: 10px;
            font-size: 14px;
        }
        img {
            max-height: 45px;
        }
    }


    @media screen and (min-width: 768px){
        .card{
            h3.text-navy-blue {
                font-size: 16px;
                white-space: nowrap;
                word-break: keep-all;
            }
        }
    }

}