#ticker{
  .pool-id{
    word-break: break-all;
  }
  .card.hover-flush {
    box-shadow: none;
    transition:0.3s;
    width: 100%;
    padding-top: 25px;
    img{
      max-width: 200px;
      margin: 0 auto 15px auto;
    }
    .bg-subtle{
      /*background: rgb(246 254 255 / 0.4);*/

      &:hover{
        background:none;
      }
    }
    &:hover {
      /*     box-shadow: none; */
      transform:translateY(-10px);
      box-shadow: 0 0 50px 0 rgb(0 0 0 / 5%);
    }
  }
  @media screen and (min-width: 768px){
    .pool-id{
      word-break: keep-all;
    }
  }
}