.timer{
  .countdown-wrapper {
    text-align: center;
    .countdown-item {
      font-size: 12px;
      line-height: 50px;
      text-transform: uppercase;
      div {
        display: inline-block;
        padding: 0 5px;
        font-weight: bold;
      }
      span {
        position: relative;
        display: block;
        font-size: 44px;
        min-width: 70px;
        background: #f8f8f8;
        padding: 10px 5px;
        color: #1c2126;
        text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
        border-radius: 5px;
        margin-bottom: -5px;
        font-weight: 200;
      }
    }
    &.countdown-wrapper-theme-dark{
      .countdown-item {
        color: #fff;
      }
      span {
        background: #f8f8f8;
        &:after {
          content: " ";
          display: block;
          position: absolute;
          top: 50%;
          width: 100%;
          height: 1px;
          background: rgba(0,0,0,.05);
          z-index: 100;
          margin-left: -5px;
        }
      }
    }


  }
}