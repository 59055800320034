#banner{
  &.hero-banner
  {
    position: relative;

    height: 100%;
    .banner-img{
      position: absolute;
      top: 0;
      right: 0;
    }
    .hero-title
    {
      color: #7954ff;
    }
    .here-sub-title
    {
      /*font-size: 24px;*/
      color: #707070;
    }
  }
  &.creative-banner
  {
    position: relative;
    overflow: hidden;
    height: 77vh;
    background:linear-gradient(220deg, #4a62ff 0%, #871fff 100%);

    .banner-img
    {
      position: relative;
      top: -20%;
    }
    .hero-footer
    {
      position: absolute;
      z-index: 1;
      bottom: -10px;
      left: 0;

      width: 100%;
      height: 200px;

      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
    }
  }
  @media (min-width: 1600px)
  {
    &.creative-banner .hero-footer
    {
      height: 300px;
    }
  }

  .bubble-set
  {
    position: relative;

    max-width: 1400px;
    margin: 0 auto;
  }
  .bubble-set div
  {
    position: absolute;

    border-radius: 50%;
    background: rgba(255, 255, 255, .04);
  }
  .bubble-set .bubble-lg
  {
    top: calc(100% - 300px);
    left: 2%;

    width: 950px;
    height: 950px;
  }
  .bubble-set .bubble-md
  {
    top: -300px;
    right: -10%;

    width: 835px;
    height: 835px;
  }
  .bubble-set .bubble-sm
  {
    top: 0;
    right: 15%;

    width: 535px;
    height: 535px;
  }

  .intro-banner
  {
    height: 80%;
  }
  #banner-anim-wrap div{
    transform:scale(0.7);
  }
  div#banner-anim-wrap {
    order: 1;
  }

  .col-lg-7.col-md-12 {
    order: 2;
  }

  div#particles-js {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .creative-banner .hero-text {
    top: 0;
    transform: none;
  }

  #banner.hero-banner {
    height: 75% !important;
  }
  @media (max-width: 991px)
  {
    &.creative-banner
    {
      /*height: 100% !important;*/
      .hero-text
      {
        /*transform: translateY(-30%);*/
      }
      .banner-img
      {
        width: 100%;
        height: auto;
        margin-top: 10%;
      }
      .hero-footer
      {
        //display: none;
      }
    }
  }

  @media screen and (min-width: 768px){
    #banner-anim-wrap div{
      transform: translateY(-30px)scale(0.7);
    }
    div#banner-anim-wrap {
      order: 2;
    }

    .col-lg-7.col-md-12 {
      order: 1;
    }


    &.hero-banner{

      .hero-title {
        font-size: 48px;
        margin-bottom: 1rem;
      }
      .here-sub-title{
        font-size: 22px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 3rem;
      }
    }
    &.creative-banner{
      .hero-text
      {
        z-index: 10;
        top: 50%;
        transform: translateY(-30%);
      }

      .hero-text{
      position: absolute;
      top: 50%;
      display: inherit;
      width: 100%;
      transform: translateY(-40%);
    }

    }


  }
  img.cardano-logo {
    max-height: 150px;
    margin: -120px auto 0 auto;
  }

}//#banner